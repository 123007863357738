<template>
  <div>
    <!-- <v-card>
        
      </v-card> -->
    <v-app-bar 
      v-if="$vuetify.breakpoint.name !== 'xs'"
      class="hidden-md-and-down headGrad"
      :color="'#005f32'"
      :height="'140px'"
      elevation="6"
      app
      dark
      hide-on-scroll
      absolute
    >
      <v-card width="100%" flat tile color="transparent">
        <v-layout wrap style="color: #272727">
          <v-flex xs12 align-self-center>
            <v-card tile dark height="40px">
              <v-layout wrap justify-end fill-height>
                <v-flex lg9></v-flex><v-spacer></v-spacer>
                <v-flex lg1 align-self-center>
                  <v-layout wrap justify-end pr-4 pr-lg-5 pr-xl-0>
                    <v-flex
                      align-self-center
                      v-for="(item, i) in icons"
                      :key="i"
                    >
                      <a :href="item.route">
                        <v-icon small color="#82827E">{{
                          item.name
                        }}</v-icon></a
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex lg1></v-flex>
                <v-spacer></v-spacer>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center fill-height px-7>
          <v-flex xs12 lg8>
            <v-layout wrap justify-center fill-height>
              <v-flex align-self-center sm6 md6 lg2>
                <v-layout wrap justify-start>
                  <v-flex xs12 align-self-start text-left>
                    <!-- <router-link class="routerlink" to="https://www.wti.org.in/"> -->
                    <a href="https://www.wti.org.in/">
                      <v-img
                        contain
                        height="80px"
                        :src="require('./../../../public/wtilogo.png')"
                      ></v-img>
                    </a>
                    <!-- </router-link> -->
                  </v-flex>
                  <!-- <v-flex xs2 sm1 md10 lg10 align-self-center text-left>
                  <div>
                    <router-link class="routerlink" :to="(name = '/')">
                      <span :class="transAppBar ? 'maintitlehid' : 'maintitle'">
                        Speleological Association of India
                      </span>
                    </router-link>
                  </div>
                </v-flex> -->
                  <!-- <v-flex xs12 class="Subt" align-self-center text-left>
                  A technology innovation challenge to #savethestripes
                </v-flex> -->
                </v-layout>
              </v-flex>

              <v-flex align-self-center xs8 sm6 md6 lg10>
                <v-layout wrap justify-center fill-height>
                  <v-flex sm12 md12 lg12 align-self-center text-center>
                    <v-layout wrap justify-center fill-height>
                      <v-flex
                        v-for="(item, i) in menus"
                        :key="i"
                        align-self-center
                        text-center
                      >
                        <a :href="item.route">
                          <span
                            style="letter-spacing: 1.5px"
                            :class="
                              $vuetify.breakpoint.name == 'md'
                                ? 'popsemibold smallm'
                                : 'popsemibold smallm'
                            "
                          >
                            <span :class="transAppBar ? 'hid' : 'hov'">
                              {{ item.name }}</span
                            >
                          </span>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-center lg1 pl-6>
            <v-card
              flat
              tile
              height="100px"
              color="#ecb338"
              class="boxx"
              style="border-bottom: 3.5px solid #60511e !important"
            >
              <v-layout wrap justify-center fill-height>
                <v-flex align-self-center xs12 py-5>
                  <a href="https://donation.wti.org.in/">
                    <span class="popregular donatehov">DONATE</span></a
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <!-- <v-flex text-center lg1 pr-16 class="">
            <v-card flat tile height="100px" color="#f7c644" 
              style="border-bottom: 3.5px solid #60511e !important">
              <v-layout wrap justify-center fill-height>
                <v-flex align-self-center xs12 py-5> 
                  <v-icon class="donatehov">mdi-cart</v-icon>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex> -->
          <!-- <v-flex xs12 v-if="$route.name != 'home'">
          <v-divider
            v-if="transAppBar"
            style="background-color: black"
          ></v-divider>
        </v-flex> -->
        </v-layout>
      </v-card>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      icons: [
        {
          name: "mdi-facebook",
          route:
            "https://www.facebook.com/pages/Wildlife-Trust-of-India/112289815480421?fref=ts",
        },
        {
          name: "mdi-linkedin",
          route: "https://in.linkedin.com/company/wildlife-trust-of-india",
        },
        {
          name: "mdi-twitter",
          route:
            "https://www.wti.org.in/job/programme-officer-emergency-relief-network/",
        },
        {
          name: "mdi-youtube",
          route: "https://www.youtube.com/user/wildlifetrustofindia",
        },
        {
          name: "mdi-instagram",
          route: "https://www.instagram.com/wildlifetrustofindia/",
        },
      ],
      sideNav: true,
      transAppBar: true,
      whiteAppBar: false,
      menu1: false,
      userNavItems: [
        { name: "ABOUT US", route: "https://www.wti.org.in/" },
        { name: "OUR WORK", route: "https://www.wti.org.in/" },
        { name: "WHY US", route: "https://www.wti.org.in/" },
        { name: "RESOURCE CENTRE", route: "https://www.wti.org.in/" },
        { name: "SHOP", route: "https://www.wti.org.in/" },
        { name: "CONTACT US", route: "https://www.wti.org.in/" },
        { name: "JOBS", route: "/" },
      ],
    };
  },
  computed: {
    menus() {
      return this.userNavItems;
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
.headGrad {
  /* background: transparent; */
  background-color: rgba(0, 95, 50, 0.85) !important;

  /* linear-gradient(104deg, #000000bb 0%, #7e7e7e2a 100%, transparent 100%); */
}
.hov {
  color: #ffffff !important;
}
.hid {
  color: #ffffff !important;
}
.hov:hover {
  color: #e27826 !important;
  /* border-top: 2px solid #097392; */
  border-bottom: 3.5px solid #90ba92;
  padding-bottom: 38px;
}
.hid:hover {
  color: #e27826 !important;
  /* border-top: 2px solid #097392; */
  border-bottom: 3.5px solid #90ba92;
  padding-bottom: 38px;
}
.boxx {
  background-color: #ecb338;
}
.donatehov {
  color: #60511e !important;
  cursor: pointer;
}
.donatehov:hover {
  color: #e27826 !important;
  cursor: pointer;
}
.v-divider {
  display: none !important;
}
</style>
