<template>
  <div>
    <!-- <v-divider></v-divider> -->
    <v-footer
      padless
      color="#3d673a"
      :min-height="$vuetify.breakpoint.name == 'xs' ? 'auto' : '140px'"
    >
      <v-layout wrap class="popregular">
        <v-flex xs12 align-self-center py-12>
          <v-layout wrap style="color: #ddd">
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-flex sm3 px-sm-2 px-md-0 md3 lg2 align-self-center>
              <v-layout wrap>
                <v-flex xs12 sm10>
                  <v-img
                    contain
                    height="108"
                    :src="require('./../../assets/Images/footerLogo1.png')"
                  ></v-img>
                </v-flex>
                <v-flex xs12 sm10 md12 py-6>
                  <a
                    target="_blank"
                    href="https://www.wti.org.in/wp-content/uploads/2021/09/WTI-Brochure-2021.pdf"
                  >
                    <v-btn
                      :x-small="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                          ? true
                          : false
                      "
                      color="#e27826"
                      class="bcolor"
                      dark
                    >
                      <span style="color: #ddd" class="xsmall"
                        >DOWNLOAD BROCHURE</span
                      >
                    </v-btn>
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sm2 md2 lg2 px-2 px-sm-0>
              <v-layout wrap>
                <v-flex xs12 align-self-center text-left pb-6>
                  <span>SITE MAP</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap>
                    <v-flex
                      align-self-center
                      text-left
                      xs12
                      v-for="(items, s) in list"
                      :key="s"
                    >
                      <v-layout wrap>
                        <v-flex xs12 align-self-center>
                          <a
                            style="text-decoration: none; color: black"
                            :href="items.route"
                          >
                            <span
                              class="hovcolor"
                              style="
                                color: #ddd;
                                padding-bottom: 18px;
                                font-size: 13px;
                              "
                            >
                              > &nbsp;&nbsp;{{ items.name }}</span
                            >
                          </a>
                        </v-flex>
                        <v-flex xs12 py-2>
                          <v-divider
                            style="border-bottom: 1px solid #382f24"
                          ></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sm4 md3 lg3 px-12>
              <v-layout wrap>
                <v-flex xs12 align-self-center text-left pb-6 px-2>
                  <span>RECENT WORKS</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex
                      align-self-center
                      xs4
                      pa-2
                      v-for="(image, i) in images"
                      :key="i"
                    >
                      <v-img contain :src="image.file"></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sm2 md2 lg2 px-2 px-sm-0>
              <v-layout wrap>
                <v-flex xs12 align-self-center text-left pb-6>
                  <span style="color: #ddd">CONTACT INFO</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout style="color: #ddd; font-size: 13px">
                    <v-flex xs12 text-left>
                      <span>
                        Address: F-13,<br />
                        Sector 8 National Capital Region (NCR) <br />
                        Noida 201301 <br />
                        India
                      </span>
                      <br />
                      <br />
                      <!-- </v-flex>
                    <v-flex xs12 text-left> -->
                      <span>
                        <b> Contact Details:</b> <br />+91-120-4143900 (30
                        lines) <br />
                        +91-120-4143933 (fax) <br />
                        <br />
                        <b> Email: </b> <br />
                        info@wti.org.in
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-spacer></v-spacer>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-card
            tile
            :height="$vuetify.breakpoint.name == 'xs' ? 'auto' : '60px'"
            flat
            dark
            color="#191612"
          >
            <v-layout wrap justify-center fill-height>
              <v-flex lg2 align-self-center></v-flex>
              <v-flex md1 lg1 align-self-center>
                <v-layout wrap>
                  <v-flex
                    align-self-center
                    text-center
                    v-for="(item, i) in icons"
                    :key="i"
                  >
                  <a :href="item.route">
                    <v-icon small>{{ item.name }}</v-icon></a>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex lg8 align-self-center>
                <span style="color: #82827e" class="popregular smallm">
                  WILDLIFE TRUST OF INDIA © 2021 | ALL RIGHTS RESERVED. |
                  DESIGNED & DEVELOPED BY Leopard Tech Labs
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          file: require("./../../assets/Images/first.jpg"),
        },
        {
          file: require("./../../assets/Images/second.jpg"),
        },
        {
          file: require("./../../assets/Images/third.jpg"),
        },
        {
          file: require("./../../assets/Images/four.jpg"),
        },
        {
          file: require("./../../assets/Images/five.jpg"),
        },
        {
          file: require("./../../assets/Images/six.jpg"),
        },
      ],
      list: [
        { name: "Our Projects", route: "https://www.wti.org.in/our-projects/" },
        { name: "Milestones", route: "https://www.wti.org.in/milestones/" },
        { name: "Why Us", route: "https://www.wti.org.in/why-us/" },
        {
          name: "Publications",
          route: "https://www.wti.org.in/resource-centre/publications/",
        },
        {
          name: "Refund and Cancellation",
          route: "/refundAndCancellation",
        },
        {
          name: "Terms and Conditions",
          route: "/termsAndCondition",
        },
        {
          name: "Privacy Policy",
          route: "/privacyPolicy",
        },
      ],
      
      icons: [
        {
          name: "mdi-facebook",
          route:
            "https://www.facebook.com/pages/Wildlife-Trust-of-India/112289815480421?fref=ts",
        },
        {
          name: "mdi-linkedin",
          route: "https://in.linkedin.com/company/wildlife-trust-of-india",
        },
        {
          name: "mdi-twitter",
          route:
            "https://www.wti.org.in/job/programme-officer-emergency-relief-network/",
        },
        {
          name: "mdi-youtube",
          route: "https://www.youtube.com/user/wildlifetrustofindia",
        },
        {
          name: "mdi-instagram",
          route: "https://www.instagram.com/wildlifetrustofindia/",
        },
      ],
      // socialMediasList: [
      //   {
      //     image: require("./../assets/images/fb.svg"),
      //     link:"https://www.facebook.com/Speleological-Association-of-India-107212508367437/?ref=pages_you_manage"
      //   },
      //   {
      //     image: require("./../assets/images/insta.png"),
      //     link:"https://instagram.com/speleoindia?utm_medium=copy_link"
      //   },
      //   {
      //     image: require("./../assets/images/twitter.svg"),
      //     link: "https://twitter.com/SpeleoIndia?s=09"

      //   },
      // ],
    };
  },
  computed: {
    menus() {
      return this.userNavItems;
    },
  },
  methods: {
    scrollToElement(id) {
      if (this.$route.path !== "/") {
        this.$router.push("/").then(() => {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
          });
        });
      } else {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
.bcolor {
  color: #e27826;
}
.bcolor:hover {
  color: #ffba00;
}
.hovcolor {
  color: white !important;
}
.hovcolor:hover {
  color: #e27826 !important;
}
</style>
