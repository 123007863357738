<template>
  <div>
    <AppHeader />
 
    <v-app-bar 
      v-if="$vuetify.breakpoint.name !== 'xs'"
      class="hidden-md-and-down headGrad"
      :color="transAppBar ? '#005f32' : '#005f32'"
      :height="'100px'"
      elevation="6"
      app
      dark
      inverted-scroll
    >
      <!-- v-scroll="onScroll" -->
      <v-card width="100%" flat color="transparent">
        <v-layout wrap justify-center fill-height px-7>
          <v-flex xs12 lg8>
            <v-layout wrap justify-center fill-height>
              <v-flex align-self-center sm6 md6 lg2>
                <v-layout wrap justify-start>
                  <v-flex xs12 align-self-start text-left>
                    <!-- <router-link class="routerlink" :to="(name = '/')"> -->
                    <a href="https://www.wti.org.in/">
                      <v-img
                        contain
                        height="80px"
                        :src="require('./../../../public/wtilogo.png')"
                      ></v-img>
                    </a>
                    <!-- </router-link> -->
                  </v-flex>
                  <!-- <v-flex xs2 sm1 md10 lg10 align-self-center text-left>
                  <div>
                    <router-link class="routerlink" :to="(name = '/')">
                      <span :class="transAppBar ? 'maintitlehid' : 'maintitle'">
                        Speleological Association of India
                      </span>
                    </router-link>
                  </div>
                </v-flex> -->
                  <!-- <v-flex xs12 class="Subt" align-self-center text-left>
                  A technology innovation challenge to #savethestripes
                </v-flex> -->
                </v-layout>
              </v-flex>

              <v-flex align-self-center xs8 sm6 md6 lg10>
                <v-layout wrap justify-center fill-height>
                  <v-flex sm12 md12 lg12 align-self-center text-center>
                    <v-layout wrap justify-center fill-height>
                      <v-flex
                        v-for="(item, i) in menus"
                        :key="i"
                        align-self-center
                        text-center
                      >
                        <a :href="item.route">
                          <span
                          style="letter-spacing:1.5px"
                            :class="
                              $vuetify.breakpoint.name == 'md'
                                ? 'popsemibold smallm'
                                : 'popsemibold smallm'
                            "
                          >
                            <span :class="transAppBar ? 'hid' : 'hov'">
                              {{ item.name }}</span
                            >
                          </span>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-center lg1  pl-6>
            <v-card flat tile height="100px" class="boxx" color="#ecb338" 
              style="border-bottom: 3.5px solid #60511e !important">
              <v-layout wrap justify-center fill-height>
                <v-flex align-self-center xs12 py-5>
                  <a href="https://donation.wti.org.in/">
                    <span class="popsregular donatehov">DONATE</span></a
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <!-- <v-flex text-center lg1 pr-16 class="">
            <v-card flat tile height="100px" color="#f7c644" 
              style="border-bottom: 3.5px solid #60511e !important">
              <v-layout wrap justify-center fill-height>
                <v-flex align-self-center xs12 py-5>
                  <v-icon class="donatehov">mdi-cart</v-icon>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex> -->
          <!-- <v-flex xs12 v-if="$route.name != 'home'">
          <v-divider
            v-if="transAppBar"
            style="background-color: black"
          ></v-divider>
        </v-flex> -->
        </v-layout>
      </v-card>
    </v-app-bar>

    <!-- <v-menu v-model="menu1" offset-y>
      <v-list>
        <v-list-item v-for="(item, i) in subs" :key="i" link>
          <v-list-item-title v-text="item"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </div>
</template>
<script>
import AppHeader from "./appHeader";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      sideNav: true,
      transAppBar: true,
      whiteAppBar: false,
      menu1: false,
      userNavItems: [
        { name: "ABOUT US", route: "https://www.wti.org.in/" },
        { name: "OUR WORK", route: "https://www.wti.org.in/" },
        { name: "WHY US", route: "https://www.wti.org.in/why-us" },
        { name: "RESOURCE CENTRE", route: "https://www.wti.org.in/" },
        { name: "SHOP", route: "https://www.wti.org.in/shop" },
        { name: "CONTACT US", route: "https://www.wti.org.in/contact-us" },
        { name: "JOBS", route: "/" },
      ],
      // subs: [
      //   { name: "Publications", route: "/Publications" },
      //   { name: "Projects", route: "/Projects" },
      // ],
    };
  },
  computed: {
    // appType() {
    //   return this.$store.state.userType;
    // },
    menus() {
      return this.userNavItems;
    },
  },
  methods: {
    // scrollToElement(id) {
    //   if (this.$route.path !== "/") {
    //     this.$router.push("/").then(() => {
    //       setTimeout(function () {
    //         if (window.innerWidth >= 2500) {
    //           document.body.scrollTop = 1300;
    //           document.documentElement.scrollTop = 1320;
    //         } else {
    //           document.body.scrollTop = 390;
    //           document.documentElement.scrollTop = 400;
    //         }
    //       }, 100);
    //       document.getElementById(id).scrollIntoView({
    //         behavior: "smooth",
    //       });
    //     });
    //   } else {
    //     document.getElementById(id).scrollIntoView({
    //       behavior: "smooth",
    //     });
    //   }
    // },
    // onScroll(e) {
    //   this.offsetTop = e.target.scrollTop;
    //   if (typeof window === "undefined") return;
    //   const top = window.pageYOffset || e.target.scrollTop || 0;
    //   if (top > 0) {
    //     this.transAppBar = true;
    //     console.log("scrolled", top)
    //   } else {
    //     this.transAppBar = true;
    //   }
    // },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
.headGrad {
  /* background: transparent; */
  background-color: rgba(0, 95, 50, 0.85) !important;

  /* linear-gradient(104deg, #000000bb 0%, #7e7e7e2a 100%, transparent 100%); */
}
.hov {
  color: #ffffff !important;
}
.hid {
  color: #ffffff !important;
}
.hov:hover {
  color: #e27826 !important;
  /* border-top: 2px solid #097392; */
  border-bottom: 3.5px solid #90ba92;
  padding-bottom: 38px;
}
.hid:hover {
  color: #e27826 !important;
  /* border-top: 2px solid #097392; */
  border-bottom: 3.5px solid #90ba92;
  padding-bottom: 38px;
}
.boxx {
  background-color: #ecb338;
}
.donatehov {
  color: #60511e !important;
  cursor: pointer;
}
.donatehov:hover {
  color: #e27826 !important;
  cursor: pointer;
}
.v-divider {
  display: none !important;
}
</style>
